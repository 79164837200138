import { useState } from "react";
import { resume } from "../../../../data/resume";
import { Reference } from "../../../../interfaces/resume.interface";
import Button from "../../../../ui/Button/Button";
import Font from "../../../../ui/Font/Font";
import Input from "../../../../ui/Input/Input";
import { randomId } from "../../../../utilities/randomId.utility";
import { Validation } from "../../../../utilities/validate.utility";
import { useNavigate } from "react-router-dom";

interface ReferencesProps {
    onNavigate: (direction: -1 | 1) => void;
}

interface FormValidators {
    fullnameValidators: Validation.Validator[];
    roleValidators: Validation.Validator[];
}

export default function References({
    onNavigate
}: ReferencesProps) {
    const [refresh, forceRefresh] = useState(true);
    const navigate = useNavigate();

    const [needToValidate, setNeedToValidate] = useState<boolean>(false);

    const formValidators: FormValidators = {
        fullnameValidators: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: ""
            }
        ],
        roleValidators: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: ""
            }
        ]
    }

    const validateForm = () => {
        if(!needToValidate) {
            setNeedToValidate(true)
        }

        let socialsValid = true;

        for(let n=0;n<resume.references.length;n++) {
            if(
                !Validation.validate(resume.references[n].fullname, formValidators.fullnameValidators).valid ||
                !Validation.validate(resume.references[n].role, formValidators.roleValidators).valid
            ) {
                socialsValid = false;
                break;
            }
        }

        if(socialsValid){
            navigate('/download');
        }
    }

    const onAddReference = () => {
        resume.references.push({
            fullname: '',
            role:'',
            contacts: '',
            id: randomId()
        });

        forceRefresh(!refresh);
    }

    const onDeleteReference = (id: string) => {
        resume.references = resume.references.filter((reference: Reference) => reference.id !== id);

        forceRefresh(!refresh);  
    }

    return (
        <div className="resume-generator__section">
            <h1>References</h1>

            <div className="resume-generator__section-form">
                <div className="resume-generator__section-form__actions">
                    <Button text="Add new reference" skin="action" onClick={onAddReference}></Button>
                </div>

                {
                    resume.references.length > 0 && <div className="contacts-container">
                        {
                            resume.references.map(
                                (reference: Reference) => <>
                                    <div className="resume-generator__section-form__line" style={{alignItems:'end'}}>
                                        <Input
                                            key={randomId()}
                                            value={reference.fullname} 
                                            onChangeValue={(value: string) => reference.fullname = value} 
                                            placeholder="Full name" 
                                            title="Full name"
                                            validators={formValidators.fullnameValidators}
                                            hasMessage={false}
                                            needToValidate={needToValidate}
                                        />
                                        <Input
                                            key={randomId()}
                                            value={reference.role} 
                                            onChangeValue={(value: string) => reference.role = value} 
                                            placeholder="Role" 
                                            title="Role"
                                            validators={formValidators.roleValidators}
                                            hasMessage={false}
                                            needToValidate={needToValidate}
                                        />
                                        <Input
                                            key={randomId()}
                                            value={reference.contacts} 
                                            onChangeValue={(value: string) => reference.contacts = value} 
                                            placeholder="Ex. email, mobile" 
                                            title="Contacts"
                                            validators={[]}
                                            hasMessage={false}
                                        />
                                        <Button 
                                            key={randomId()}
                                            text="Delete" 
                                            skin="danger" 
                                            style={{ height: '36px', minWidth: '5rem' }}
                                            onClick={() => onDeleteReference(reference.id)}
                                        ></Button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                }
            </div>

            <div className="resume-generator__section-navigation">
                <Button text="Back" skin="dark" onClick={() => onNavigate(-1)}></Button>
                <Button text="Download Resume" skin="action" onClick={() => validateForm()}></Button>
            </div>
        </div>
    );
}