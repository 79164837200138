import './Checkbox.scss';
import './../Input/Input.scss';
import Font from '../Font/Font';

export interface CheckboxProps {
    label: string;
    onChange?: (value: boolean) => void;
    defaultChecked?: boolean;
}

export default function Checkbox({
    label,
    onChange,
    defaultChecked=false
}: CheckboxProps) {
    return (
        <div className='checkbox'>
            <input defaultChecked={defaultChecked} type="checkbox" className='checkbox-input' onChange={(e) => onChange?.(e.target.checked)}></input>
            <Font>{label}</Font>
        </div>
    );
}