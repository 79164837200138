import { useState } from "react";
import { resume } from "../../../../data/resume";
import { MainSubsection } from "../../../../interfaces/resume.interface";
import Button from "../../../../ui/Button/Button";
import Font from "../../../../ui/Font/Font";
import Input from "../../../../ui/Input/Input";
import { Validation } from "../../../../utilities/validate.utility";
import Checkbox from "../../../../ui/Checkbox/Checkbox";
import { randomId } from "../../../../utilities/randomId.utility";

export interface WorkExperienceProps {
    onNavigate: (direction: -1 | 1) => void;
}

interface FormValidators {
    companyName: Validation.Validator[];
    position: Validation.Validator[];
}

export default function WorkExperience({
    onNavigate
}: WorkExperienceProps) {
    const [refresh, forceRefresh] = useState(true);

    const [needToValidate, setNeedToValidate] = useState<boolean>(false);

    const formValidators: FormValidators = {
        companyName: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: "Invalid name"
            }
        ],
        position: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: "Invalid position"
            }
        ]
    }

    const validateForm = () => {
        if(!needToValidate) {
            setNeedToValidate(true)
        }

        let socialsValid = true;

        for(let n=0;n<resume.experiences.length;n++) {
            if(
                !Validation.validate(resume.experiences[n].title, formValidators.companyName).valid ||
                !Validation.validate(resume.experiences[n].subtitle, formValidators.position).valid
            ) {
                socialsValid = false;
                break;
            }
        }

        if(socialsValid){
            onNavigate(1);
        }
    }

    const onAddWorkExperience = () => {
        resume.experiences.push({
            title: "",
            subtitle: "",
            startDate: new Date(),
            description: "",
            endDate: new Date(),
            hasEndDate: true,
            id: randomId()
        })

        forceRefresh(!refresh);
    }

    const onDeleteWorkExperience = (id: string) => {
        resume.experiences = resume.experiences.filter((experience: MainSubsection) => experience.id !== id);

        forceRefresh(!refresh);
    }

    return (
        <div className="resume-generator__section">
            <h1>Work Experience</h1>

            <div className="resume-generator__section-form">
                <div className="resume-generator__section-form__actions">
                    <Button text="Add new work experience" skin="action" onClick={onAddWorkExperience}></Button>
                </div>

                {
                    resume.experiences.map((experience: MainSubsection) => (
                        <div className="resume-generator__section-form__subsection">
                            <div className="resume-generator__section-form__line">
                                <Input 
                                    value={experience.title} 
                                    onChangeValue={(value: string) => experience.title = value} 
                                    placeholder="Enter the company name" 
                                    title="Company name"
                                    validators={formValidators.companyName}
                                    needToValidate={needToValidate}
                                />
                                <Input 
                                    value={experience.subtitle} 
                                    onChangeValue={(value: string) => experience.subtitle = value} 
                                    placeholder="Enter your position" 
                                    title="Position"
                                    validators={formValidators.position}
                                    needToValidate={needToValidate}
                                />
                                <Input 
                                    date={experience.startDate} 
                                    type="date"
                                    onChangeDate={(date: Date) => {
                                        experience.startDate = date;
                                        forceRefresh(!refresh)
                                    }} 
                                    placeholder="Enter start date" 
                                    title="Start date"
                                    validators={[
                                        {
                                            validatorType: Validation.ValidationType.REQUIRED,
                                            message: "Invalid date"
                                        }
                                    ]}
                                />
                                {
                                    experience.hasEndDate && <Input            
                                        date={experience.endDate} 
                                        type="date"
                                        onChangeDate={(date: Date) => {
                                            experience.endDate = date;
                                            forceRefresh(!refresh)
                                        }} 
                                        placeholder="Enter end date" 
                                        title="End date"
                                        validators={[
                                            {
                                                validatorType: Validation.ValidationType.REQUIRED,
                                                message: "Invalid date"
                                            }
                                        ]}
                                    />
                                }
                            </div>
                            <div className="resume-generator__section-form__checkbox">
                                <Checkbox defaultChecked={!experience.hasEndDate} label="I am working here" onChange={(checked: boolean) => {
                                    experience.hasEndDate = !checked;
                                    forceRefresh(!refresh)
                                }}></Checkbox>
                            </div>
                            <div className="resume-generator__section-form__line resume-generator__section-form__line--w100">
                                <Input 
                                    type="textarea"
                                    value={experience.description} 
                                    onChangeValue={(value: string) => experience.description = value} 
                                    placeholder="Descript your experience" 
                                    title="Description"
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Button 
                                    key={randomId()}
                                    text="Delete" 
                                    skin="danger" 
                                    style={{ height: '36px', minWidth: '5rem' }}
                                    onClick={() => onDeleteWorkExperience(experience.id)}
                                ></Button>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="resume-generator__section-navigation">
                <Button text="Back" skin="dark" onClick={() => onNavigate(-1)}></Button>
                <Button text="Next" skin="action" onClick={() => validateForm()}></Button>
            </div>
        </div>
    )
}