import { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { resumeTemplates } from '../../data/resumeTemplates';
import { ResumeTemplate } from '../../interfaces/resumeTemplate.interface';
import Button from '../../ui/Button/Button';
import Font from '../../ui/Font/Font';
import { documentToBlob } from '../../utilities/documentToBlob.utility';
import { downloadFile } from '../../utilities/downloadFiles.utility';
import './Download.scss';
import { useNavigate } from 'react-router-dom';

export default function Download() {
    const navigate = useNavigate();

    const [selectedResumeTemplateId, setSelectedResumeTemplateId] = useState(1);
    
    const download = async () => {
        const fileBuffer = await documentToBlob(resumeTemplates.filter((template: ResumeTemplate) => template.id === selectedResumeTemplateId)[0].getDocument());
        downloadFile(fileBuffer);
    }

    const onSelectResumeTemplate = (template: ResumeTemplate) => {
        setSelectedResumeTemplateId(template.id);
    }

    return (
        <>
            <Navbar></Navbar>
            <div className='download'>
                <Font weight="lg" customSize="2.5rem" element="p">Select template</Font>
                <div className='download-description'>
                    <Font size='m'>All the information entered during compilation will be automatically incorporated into the selected template and subsequently downloaded with a .docx file extension (Word format).</Font>
                </div>

                <div className='download-templates'>
                    {
                        resumeTemplates.map((template: ResumeTemplate) => {
                            return (
                                <div className={'download-templates-template ' + (template.id === selectedResumeTemplateId ? 'download-templates-template--selected' : '')} onClick={() => onSelectResumeTemplate(template)}>
                                    <img alt="logo" src={template.preview} className='download-templates-template-image'></img>
                                </div>
                            )
                        })
                    }
                </div>

                <div style={{ display: 'flex', gap: '20px' }}>
                    <Button text="Back" skin="dark" onClick={() => navigate('/resume-generator')}></Button>
                    <Button onClick={download} text="Download" skin='action'></Button>
                </div>
            </div>
        </>
    );
}