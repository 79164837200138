import Slick from "../../../ui/Slick/Slick";
import './Templates.scss'

export default function Templates() {
    return (
        <div className="templates" id="templates-section">
            <h1 className="section-title">Choose from a variety of Resume Templates</h1>

            <div className="templates-content">
                <Slick></Slick>
            </div>
        </div>
    )
}