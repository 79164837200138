import { useState } from "react";
import { resume } from "../../../../data/resume";
import Font from "../../../../ui/Font/Font";
import Button from "../../../../ui/Button/Button";
import { MainSubsection } from "../../../../interfaces/resume.interface";
import Input from "../../../../ui/Input/Input";
import { Validation } from "../../../../utilities/validate.utility";
import Checkbox from "../../../../ui/Checkbox/Checkbox";
import { randomId } from "../../../../utilities/randomId.utility";

interface PersonalProjectsProps {
    onNavigate: (direction: -1 | 1) => void
}

interface FormValidators {
    projectName: Validation.Validator[];
}

export default function PersonalProjects({
    onNavigate
}: PersonalProjectsProps) {
    const [refresh, forceRefresh] = useState(true);

    const [needToValidate, setNeedToValidate] = useState<boolean>(false);

    const formValidators: FormValidators = {
        projectName: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: "Invalid name"
            }
        ]
    }

    const validateForm = () => {
        if(!needToValidate) {
            setNeedToValidate(true)
        }

        let socialsValid = true;

        for(let n=0;n<resume.personalProjects.length;n++) {
            if(
                !Validation.validate(resume.personalProjects[n].title, formValidators.projectName).valid
            ) {
                socialsValid = false;
                break;
            }
        }

        if(socialsValid){
            onNavigate(1);
        }
    }

    const onAddPersonalProjects = () => {
        resume.personalProjects.push({
            title: "",
            subtitle: "",
            startDate: new Date(),
            description: "",
            endDate: new Date(),
            hasEndDate: true,
            id : randomId()
        })

        forceRefresh(!refresh);
    }

    const onDeletePersonalProject = (id: string) => {
        resume.personalProjects = resume.personalProjects.filter((personalProject: MainSubsection) => personalProject.id !== id);

        forceRefresh(!refresh);
    }

    return (
        <div className="resume-generator__section">
            <h1>Personal Projects</h1>

            <div className="resume-generator__section-form">
                <div className="resume-generator__section-form__actions">
                    <Button text="Add new personal project" skin="action" onClick={onAddPersonalProjects}></Button>
                </div>

                {
                    resume.personalProjects.map((personalProject: MainSubsection) => (
                        <div className="resume-generator__section-form__subsection">
                            <div className="resume-generator__section-form__line">
                                <Input 
                                    value={personalProject.title} 
                                    onChangeValue={(value: string) => personalProject.title = value} 
                                    placeholder="Enter the project name" 
                                    title="Project name"
                                    validators={formValidators.projectName}
                                    needToValidate={needToValidate}
                                />
                                <Input 
                                    value={personalProject.subtitle} 
                                    onChangeValue={(value: string) => personalProject.subtitle = value} 
                                    placeholder="Enter the project link" 
                                    title="Project link"
                                    validators={[]}
                                />
                                <Input 
                                    date={personalProject.startDate} 
                                    type="date"
                                    onChangeDate={(date: Date) => {
                                        personalProject.startDate = date;
                                        forceRefresh(!refresh)
                                    }} 
                                    placeholder="Enter start date" 
                                    title="Start date"
                                    validators={[
                                        {
                                            validatorType: Validation.ValidationType.REQUIRED,
                                            message: "Invalid date"
                                        }
                                    ]}
                                />
                                {
                                    personalProject.hasEndDate && <Input            
                                    date={personalProject.endDate} 
                                    type="date"
                                    onChangeDate={(date: Date) => {
                                        personalProject.endDate = date;
                                        forceRefresh(!refresh)
                                    }} 
                                    placeholder="Enter end date" 
                                    title="End date"
                                    validators={[
                                        {
                                            validatorType: Validation.ValidationType.REQUIRED,
                                            message: "Invalid position"
                                        }
                                    ]}
                                />
                                }
                            </div>
                            <div className="resume-generator__section-form__checkbox">
                                <Checkbox defaultChecked={!personalProject.hasEndDate} label="I am still working on it" onChange={(checked: boolean) => {
                                    personalProject.hasEndDate = !checked;
                                    forceRefresh(!refresh)
                                }}></Checkbox>
                            </div>
                            <div className="resume-generator__section-form__line resume-generator__section-form__line--w100">
                                <Input 
                                    type="textarea"
                                    value={personalProject.description} 
                                    onChangeValue={(value: string) => personalProject.description = value} 
                                    placeholder="Descript the project" 
                                    title="Description"
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Button 
                                    key={randomId()}
                                    text="Delete" 
                                    skin="danger" 
                                    style={{ height: '36px', minWidth: '5rem' }}
                                    onClick={() => onDeletePersonalProject(personalProject.id)}
                                ></Button>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="resume-generator__section-navigation">
                <Button text="Back" skin="dark" onClick={() => onNavigate(-1)}></Button>
                <Button text="Next" skin="action" onClick={() => validateForm()}></Button>
            </div>
        </div>
    )
}