import { ResumeTemplate } from "../interfaces/resumeTemplate.interface";
import { getTemplate1 } from "../resume-templates/template1";
import { getTemplate2 } from "../resume-templates/template2";
import { getTemplate3 } from "../resume-templates/template3";
import { getTemplate4 } from "../resume-templates/template4";
import { getTemplate5 } from "../resume-templates/template5";
import { getTemplate6 } from "../resume-templates/template6";
import { getTemplate7 } from "../resume-templates/template7";

export const resumeTemplates: ResumeTemplate[] = [
    {
        id: 1,
        preview: 'Template1.png',
        getDocument: () => getTemplate1()
    },
    {
        id: 2,
        preview: 'Template2.png',
        getDocument: () => getTemplate2()
    },
    {
        id: 3,
        preview: 'Template3.png',
        getDocument: () => getTemplate3()
    },
    {
        id: 4,
        preview: 'Template4.png',
        getDocument: () => getTemplate4()
    },
    {
        id: 5,
        preview: 'Template5.png',
        getDocument: () => getTemplate5()
    },
    {
        id: 6,
        preview: 'Template6.png',
        getDocument: () => getTemplate6()
    },
    {
        id: 7,
        preview: 'Template7.png',
        getDocument: () => getTemplate7()
    }
];