import { AlignmentType, Document, HeadingLevel } from "docx";
import { renderHeader, renderSkills, renderReferences, renderSection } from "./resume.helper";
import { resume } from "../data/resume";

export const getTemplate2 = () => {
    return new Document({
        sections : [
            {
                children : [
                    ...renderHeader({ text: resume.name + " " + resume.lastname, heading: HeadingLevel.TITLE, alignment: AlignmentType.CENTER }, { alignment: AlignmentType.CENTER,}),
                    ...renderSkills({ text: "Skills", heading: HeadingLevel.HEADING_2, thematicBreak: false, }),
                    ...renderSection({ text: "Education", heading: HeadingLevel.HEADING_2, thematicBreak: false, }, resume.educations),
                    ...renderSection({ text: "Work Experience", heading: HeadingLevel.HEADING_2, thematicBreak: false, }, resume.experiences),
                    ...renderSection({ text: "Personal Projects", heading: HeadingLevel.HEADING_2, thematicBreak: false, }, resume.personalProjects),
                    ...renderReferences({ text: "References", heading: HeadingLevel.HEADING_2, thematicBreak: false, })
                ]
            }
        ]
    })
}