import RemoveIcon from "../../Icons/RemoveIcon/RemoveIcon";
import Font from "../Font/Font";
import './Badge.scss';

interface BadgeProps {
    text: string;
    onRemove?: () => void;
}

export default function Badge({
    text,
    onRemove
}: BadgeProps){
    return (
        <div className="badge">
            <div className="badge-text">
                <Font>{text}</Font>
            </div>
            <div className="badge-remove" onClick={() => onRemove?.()}>
                <RemoveIcon></RemoveIcon>
            </div>
        </div>
    )
}