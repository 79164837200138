import { AlignmentType, Document, HeadingLevel, Paragraph } from "docx";
import { renderHeader, renderSkills, renderReferences, renderSection } from "./resume.helper";
import { resume } from "../data/resume";

export const getTemplate7 = () => {
    return new Document({
        styles : {
            paragraphStyles : [
                {
                    id : 'CVNAME',
                    run : {
                        allCaps: false,
                        bold: true, 
                        size: 35
                    }
                }
            ]
        },
        sections : [
            {
                children : [
                    ...renderHeader(
                        { style : 'CVNAME', text: resume.name + " " + resume.lastname, heading: HeadingLevel.TITLE, alignment: AlignmentType.LEFT }, 
                        { alignment: AlignmentType.LEFT, thematicBreak: true,},
                        { after: 100 }
                    ),
                    new Paragraph({
                        spacing: {
                            before: 100
                        }
                    }),
                    ...renderSection({ style: 'PARAGRAPH', text: "Education", heading: HeadingLevel.HEADING_1, thematicBreak: false, }, resume.educations),
                    ...renderSection({ style: 'PARAGRAPH', text: "Work Experience", heading: HeadingLevel.HEADING_1, thematicBreak: false, }, resume.experiences),
                    ...renderSection({ style: 'PARAGRAPH', text: "Personal Projects", heading: HeadingLevel.HEADING_1, thematicBreak: false, }, resume.personalProjects),
                    new Paragraph({
                        spacing: {
                            before: 100,
                            after: 300
                        },
                        thematicBreak: true
                    }),
                    ...renderSkills({ style: 'PARAGRAPH', text: "Skills", heading: HeadingLevel.HEADING_1, thematicBreak: false, }),
                    ...renderReferences({ style: 'PARAGRAPH', text: "References", heading: HeadingLevel.HEADING_1, thematicBreak: false, }),
                ]
            }
        ]
    })
}