import Accordion from '../../../ui/Accordion/Accordion';
import './Faq.scss';

export default function Faq() {    
    return (
        <div className="faq" id="faq-section">
            <h1 className="section-title">Frequently asked questions</h1>

            <div className="faq-content">
                <Accordion title="What is an ATS-friendly resume?" content='An ATS-friendly resume is a document formatted in a way that allows Applicant Tracking Systems (ATS) to scan and parse the information efficiently. This increases the chances of your resume being selected by automated systems during the initial screening process.'></Accordion>
                <Accordion title="Why is it important to have an ATS-friendly resume?" content='Many employers use ATS to manage job applications. Having an ATS-friendly resume ensures that your information is correctly interpreted by these systems, increasing the likelihood of your resume reaching the hands of human recruiters for further consideration.'></Accordion>
                <Accordion title="Which format should I use for an ATS-friendly resume?" content='Use a simple and clean format. Stick to standard fonts, avoid images or graphics, and use common section headings. A plain text (.txt) or a Word document (.doc or .docx) is generally preferable for ATS compatibility.'></Accordion>
            </div>
        </div>
    );
}