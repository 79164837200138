import { skin } from '../../types/skin.type';
import Font, { FontProps } from '../Font/Font';
import './Button.scss';

export interface ButtonProps {
    text: string;
    fontProps?: FontProps;
    skin?: skin;
    outlined?: boolean;
    style?: React.CSSProperties | undefined;
    onClick?: () => void;
    disabled?: boolean;
}

export default function Button({
    text,
    skin='primary',
    outlined=false,
    fontProps={ weight: 'lg' },
    style,
    onClick,
    disabled=false
}: ButtonProps) {
    const getButtonClasses = (): string => {
        const classes: string[] = [
            'button',
            `button--${skin}`
        ];

        if(outlined) {
            classes.push('button--outlined');
        }

        return classes.join(' ');
    }

    return (
        <button disabled={disabled} onClick={() => onClick?.()} style={style} className={getButtonClasses()}>
            <Font {...fontProps}>{text}</Font>
        </button>
    );
}