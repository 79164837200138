import { Step } from "../../../interfaces/step.interface";
import { steps } from "../../../data/steps.data";
import Font from "../../../ui/Font/Font";
import './ResumeGeneratorProgress.scss';
import { StepType } from "../../../types/stepType.enum";

export interface ResumeGeneratorProgressProps {
    currentStep: StepType;
    onSelectStep: (step: Step) => void;
}

export default function ResumeGeneratorProgress({
    onSelectStep,
    currentStep,
}: ResumeGeneratorProgressProps) {
    return (
        <div className="progress">
            {
                steps.map((step: Step, index: number) => {
                    return (
                        <>
                            <div 
                                className={`
                                    progress-step 
                                    ${currentStep === step.step ? 'progress-step--current' : null} 
                                `} 
                                onClick={/* () => onSelectStep(step) */ () => {}}>
                                <div className="progress-step__circle">
                                    <Font>{(index + 1).toString()}</Font>
                                </div>
                                <Font align="center">{step.title}</Font>
                            </div>
                            {
                                (index < steps.length - 1) && <div className="progress-line"></div>
                            }
                        </>
                    );
                })
            }
        </div>
    );
}