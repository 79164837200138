import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './ui/style.scss';
import './ui/neutral-style.scss';
import Home from './Pages/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResumeGenerator from './Pages/ResumeGenerator/ResumeGenerator';
import Footer from './components/Footer/Footer';
import Download from './Pages/Download/Download';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div id="container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/resume-generator" element={<ResumeGenerator />}></Route>
          <Route path="/download" element={<Download />}></Route>
          <Route path='*' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
    <Footer></Footer>
  </React.StrictMode>
);

reportWebVitals();
