import { IconType } from "../../types/icon.type";
import { renderIcon } from "../../utilities/renderIcon.utility";
import Font from "../Font/Font";
import "./Card.scss";

interface CardProps {
    icon: IconType;
    title: string;
    description: string;
}

export default function Card({
    icon,
    title,
    description
}: CardProps) {
    return (
        <div className="card">
            <div className="card-icon">
                {
                    renderIcon(icon)
                }
            </div>
            <div className="card-title">
                <Font align="center" size="lg" weight="lg">{title}</Font>
            </div>
            <div className="card-description">
                <Font customSize="1rem">{description}</Font>
            </div>
        </div>
    );
}