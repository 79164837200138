const SimpleFormatIcon = (props: any) => (
  <svg
    fill="#b4b6de"
    viewBox="0 0 64 64"
    data-name="Layer 1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title />
    <path d="M48.5,58.5h-33a2,2,0,0,1-2-2V7.5a2,2,0,0,1,2-2H38.07a2,2,0,0,1,1.58.77L50.08,19.63a2,2,0,0,1,.42,1.23v5a2,2,0,0,1-4,0V21.55l-9.4-12H17.5v45h29V34.69a2,2,0,0,1,4,0V56.5A2,2,0,0,1,48.5,58.5Z" />
    <path d="M39.66,40.36H24.34a2,2,0,0,1,0-4H39.66a2,2,0,0,1,0,4Z" />
    <path d="M39.66,49.36H24.34a2,2,0,0,1,0-4H39.66a2,2,0,0,1,0,4Z" />
  </svg>
);
export default SimpleFormatIcon;