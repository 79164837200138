import { useEffect } from 'react';
import Button from '../../ui/Button/Button';
import './Navbar.scss';
import { useLocation, useNavigate } from 'react-router-dom'
import { NAVBAR_HEIGHT } from '../../ui/style.const';

type sectionType = 'home' | 'about' | 'ats-system' | 'templates' | 'faq';

export default function Navbar() {
    const navigate = useNavigate();
    const { state } = useLocation();

    const onNavbarClick = (sectionType: sectionType) => {
        navigate('/', { state: { sectionType } });
    }

    useEffect(() => {
        if (!state) return;

        const top = (document.getElementById(`${state.sectionType}-section`)?.offsetTop || 0) - NAVBAR_HEIGHT;
        window.scrollTo({ top, behavior: 'smooth' });
    })

    return (
        <div className={"navbar " + ((window.location.pathname !== "/" && window.location.pathname !== '/ats-friendly/') ? 'navbar-black' : 'navbar-white')}>
            <div className="navbar_left-side">
                <div className="navbar_left-side_logo" onClick={() => navigate('/')}>
                    <p className="navbar_left-side_logo-text">ats.friendly</p>
                </div>
            </div>

            <div className="navbar_right-side">
                <div className='navbar_right-side_sections-container'>
                    <div className='navbar_right-side_sections-container_section'>
                        <p
                            className='navbar_right-side_sections-container_section-link'
                            onClick={() => onNavbarClick('home')}
                        >Home</p>
                    </div>
                    <div className='navbar_right-side_sections-container_section'>
                        <p
                            className='navbar_right-side_sections-container_section-link'
                            onClick={() => onNavbarClick('about')}
                        >About</p>
                    </div>
                    <div className='navbar_right-side_sections-container_section'>
                        <p
                            className='navbar_right-side_sections-container_section-link'
                            onClick={() => onNavbarClick('ats-system')}
                        >ATS system</p>
                    </div>
                    <div className='navbar_right-side_sections-container_section'>
                        <p
                            className='navbar_right-side_sections-container_section-link'
                            onClick={() => onNavbarClick('templates')}
                        >Templates</p>
                    </div>
                    <div className='navbar_right-side_sections-container_section'>
                        <p
                            className='navbar_right-side_sections-container_section-link'
                            onClick={() => onNavbarClick('faq')}
                        >FAQ</p>
                    </div>
                </div>
                <div className='navbar_right-side_actions-container'>
                    {/* <Button style={{ color: 'var(--secondary-text-color)' }} text="Try an example"></Button> */}
                    <Button skin='action' style={{ color: 'var(--secondary-text-color)' }} text="Get started" onClick={() => navigate('/resume-generator')}></Button>
                </div>
            </div>
        </div>
    );
}