import { Step } from "../interfaces/step.interface";
import { StepType } from "../types/stepType.enum";

export const steps: Step[] = [
    {
        step: StepType.PERSONAL_INFORMATIONS,
        title: "Personal Informations"
    },
    {
        step: StepType.CONTACTS,
        title: "Contacts"
    },
    {
        step: StepType.SKILLS,
        title: "Skills"
    },
    {
        step: StepType.WORK_EXPERIENCE,
        title: "Work Experience"
    },
    {
        step: StepType.EDUCATION,
        title: "Education"
    },
    {
        step: StepType.PERSONAL_PROJECTS,
        title: "Personal Projects"
    },
    {
        step: StepType.REFERENCES,
        title: "References"
    }
]