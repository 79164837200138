export const UnusualCharactersIcon = (props: any) => (
  <svg
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n    .st0{fill:#b4b6de;stroke:#71749c;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n  "
      }
    </style>
    <rect x={3} y={3} className="st0" width={16} height={16} />
    <polyline className="st0" points="13,19 13,29 29,29 29,13 20,13 " />
    <line className="st0" x1={11} y1={6} x2={11} y2={8} />
    <line className="st0" x1={7} y1={9} x2={15} y2={9} />
    <path className="st0" d="M13,9c0,3.3-2.7,6-6,6" />
    <path className="st0" d="M9,9c0,3.3,2.7,6,6,6" />
    <polyline className="st0" points="17,25 21,17 25,25 " />
    <line className="st0" x1={19} y1={23} x2={23} y2={23} />
  </svg>
);