import { useState } from "react";
import Button from "../../../../ui/Button/Button";
import Font from "../../../../ui/Font/Font";
import { resume } from "../../../../data/resume";
import { MainSubsection } from "../../../../interfaces/resume.interface";
import Input from "../../../../ui/Input/Input";
import { Validation } from "../../../../utilities/validate.utility";
import Checkbox from "../../../../ui/Checkbox/Checkbox";
import { randomId } from "../../../../utilities/randomId.utility";

export interface EducationProps {
    onNavigate: (direction: -1 | 1) => void;
}

interface FormValidators {
    schoolName: Validation.Validator[];
    courseName: Validation.Validator[];
}

export default function Education({
    onNavigate
}: EducationProps) {
    const [refresh, forceRefresh] = useState(true);

    const [needToValidate, setNeedToValidate] = useState<boolean>(false);

    const formValidators: FormValidators = {
        schoolName: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: "Invalid name"
            }
        ],
        courseName: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: "Invalid name"
            }
        ]
    }

    const validateForm = () => {
        if(!needToValidate) {
            setNeedToValidate(true)
        }

        let socialsValid = true;

        for(let n=0;n<resume.educations.length;n++) {
            if(
                !Validation.validate(resume.educations[n].title, formValidators.schoolName).valid ||
                !Validation.validate(resume.educations[n].subtitle, formValidators.courseName).valid
            ) {
                socialsValid = false;
                break;
            }
        }

        if(socialsValid){
            onNavigate(1);
        }
    }

    const onAddEducation = () => {
        resume.educations.push({
            title: "",
            subtitle: "",
            startDate: new Date(),
            description: "",
            endDate: new Date(),
            hasEndDate: true,
            id : randomId()
        })

        forceRefresh(!refresh);
    }

    const onDeleteEducation = (id: string) => {
        resume.educations = resume.educations.filter((education: MainSubsection) => education.id !== id);

        forceRefresh(!refresh);
    }
    
    return (
        <div className="resume-generator__section">
            <h1>Education</h1>

            <div className="resume-generator__section-form">
                <div className="resume-generator__section-form__actions">
                    <Button text="Add new education" skin="action" onClick={onAddEducation}></Button>
                </div>

                {
                    resume.educations.map((education: MainSubsection) => (
                        <div className="resume-generator__section-form__subsection">
                            <div className="resume-generator__section-form__line">
                                <Input 
                                    value={education.title} 
                                    onChangeValue={(value: string) => education.title = value} 
                                    placeholder="Enter the school name" 
                                    title="School name"
                                    validators={formValidators.schoolName}
                                    needToValidate={needToValidate}
                                />
                                <Input 
                                    value={education.subtitle} 
                                    onChangeValue={(value: string) => education.subtitle = value} 
                                    placeholder="Enter the course name" 
                                    title="Course name"
                                    validators={formValidators.courseName}
                                    needToValidate={needToValidate}
                                />
                                <Input 
                                    date={education.startDate} 
                                    type="date"
                                    onChangeDate={(date: Date) => {
                                        education.startDate = date;
                                        forceRefresh(!refresh)
                                    }} 
                                    placeholder="Enter start date" 
                                    title="Start date"
                                    validators={[
                                        {
                                            validatorType: Validation.ValidationType.REQUIRED,
                                            message: "Invalid position"
                                        }
                                    ]}
                                />
                                {
                                    education.hasEndDate && <Input            
                                    date={education.endDate} 
                                    type="date"
                                    onChangeDate={(date: Date) => {
                                        education.endDate = date;
                                        forceRefresh(!refresh)
                                    }} 
                                    placeholder="Enter end date" 
                                    title="End date"
                                    validators={[
                                        {
                                            validatorType: Validation.ValidationType.REQUIRED,
                                            message: "Invalid position"
                                        }
                                    ]}
                                />
                                }
                            </div>
                            <div className="resume-generator__section-form__checkbox">
                                <Checkbox defaultChecked={!education.hasEndDate} label="I am studying here" onChange={(checked: boolean) => {
                                    education.hasEndDate = !checked;
                                    forceRefresh(!refresh)
                                }}></Checkbox>
                            </div>
                            <div className="resume-generator__section-form__line resume-generator__section-form__line--w100">
                                <Input 
                                    type="textarea"
                                    value={education.description} 
                                    onChangeValue={(value: string) => education.description = value} 
                                    placeholder="Descript your experience" 
                                    title="Description"
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Button 
                                    key={randomId()}
                                    text="Delete" 
                                    skin="danger" 
                                    style={{ height: '36px', minWidth: '5rem' }}
                                    onClick={() => onDeleteEducation(education.id)}
                                ></Button>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className="resume-generator__section-navigation">
                <Button text="Back" skin="dark" onClick={() => onNavigate(-1)}></Button>
                <Button text="Next" skin="action" onClick={() => validateForm()}></Button>
            </div>
        </div>
    )
}