import { MutableRefObject, useRef, useState } from "react";
import { resume } from "../../../../data/resume";
import Badge from "../../../../ui/Badge/Badge";
import Button from "../../../../ui/Button/Button";
import Font from "../../../../ui/Font/Font";
import Input from "../../../../ui/Input/Input";
import './Skills.scss';
import { Skill } from "../../../../interfaces/resume.interface";

export interface SkillsProps {
    onNavigate: (direction: -1 | 1) => void;
}

export default function Skills({
    onNavigate
}: SkillsProps) {
    const [refresh, forceRefresh] = useState(true);

    const inputRef = useRef<any>();

    const onAddNewSkill = (name: string) => {
        if(name.trim() !== "") {
            resume.skills.push({
                name,
                id: (Math.random() + 1).toString()
            });
            forceRefresh(!refresh);
        }
    }
    
    return (
        <div className="resume-generator__section">
            <h1>Skills</h1>

            <div className="resume-generator__section-form">
                <div className="resume-generator__section-form__line">
                    <Input
                        ref={inputRef}
                        placeholder="Add new skill"
                        onPressEnter={(value: string) => onAddNewSkill(value)}
                    ></Input>
                    <Button 
                        text="Add" 
                        skin="action" 
                        style={{ height: '36px', minWidth: '5rem' }}
                        onClick={() => {
                            onAddNewSkill(inputRef.current.value);
                        }}
                    ></Button>
                    <div className="divider"></div>
                </div>

                {
                    resume.skills.length > 0 ? (
                        <div className="skills-container">
                            {
                                resume.skills.map((skill: Skill) => {
                                    return (
                                        <Badge text={skill.name} onRemove={() => {
                                            resume.skills = resume.skills.filter((_skill: Skill) => _skill.id !== skill.id);
                                            forceRefresh(!refresh);
                                        }}></Badge>
                                    );
                                })
                            }
                        </div>
                    ) : null
                }
            </div>

            <div className="resume-generator__section-navigation">
                <Button text="Back" skin="dark" onClick={() => onNavigate(-1)}></Button>
                <Button text="Next" skin="action" onClick={() => onNavigate(1)}></Button>
            </div>
        </div>
    );
}