import { AlignmentType, Document, HeadingLevel, Paragraph } from "docx";
import { renderHeader, renderSkills, renderReferences, renderSection } from "./resume.helper";
import { resume } from "../data/resume";

export const getTemplate6 = () => {
    return new Document({
        styles : {
            paragraphStyles : [
                {
                    id : 'CVNAME',
                    run : {
                        allCaps: true,
                        bold: true, 
                        size: 50
                    }
                }, {
                    id : 'PARAGRAPH',
                    run : {
                        bold: true, 
                        size: 30,
                        color: '000000'
                    }
                }
            ]
        },
        sections : [
            {
                children : [
                    ...renderHeader(
                        { style : 'CVNAME', text: resume.name + " " + resume.lastname, heading: HeadingLevel.TITLE, alignment: AlignmentType.CENTER }, 
                        { alignment: AlignmentType.CENTER, thematicBreak: true,},
                        { after: 100 }
                    ),
                    new Paragraph({
                        spacing: {
                            before: 100
                        }
                    }),
                    ...renderSkills({ style: 'PARAGRAPH', text: "Skills", heading: HeadingLevel.HEADING_1, thematicBreak: false, }),
                    ...renderSection({ style: 'PARAGRAPH', text: "Education", heading: HeadingLevel.HEADING_1, thematicBreak: false, }, resume.educations),
                    ...renderSection({ style: 'PARAGRAPH', text: "Work Experience", heading: HeadingLevel.HEADING_1, thematicBreak: false, }, resume.experiences),
                    ...renderSection({ style: 'PARAGRAPH', text: "Personal Projects", heading: HeadingLevel.HEADING_1, thematicBreak: false, }, resume.personalProjects),
                    ...renderReferences({ style: 'PARAGRAPH', text: "References", heading: HeadingLevel.HEADING_1, thematicBreak: false, })
                ]
            }
        ]
    })
}