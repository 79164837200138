import { AlignmentType, Document, HeadingLevel, Paragraph } from "docx";
import { renderHeader, renderSkills, renderReferences, renderSection } from "./resume.helper";
import { resume } from "../data/resume";

export const getTemplate3 = () => {
    return new Document({
        styles : {
            paragraphStyles : [
                {
                    id: 'sectionTitle',
                    run : {
                        size: 35,
                        color: '#fc0f1b',
                    },
                }            
            ]
        },
        sections : [
            {
                children : [
                    ...renderHeader({ text: resume.name + " " + resume.lastname, heading: HeadingLevel.TITLE, alignment: AlignmentType.CENTER }, { alignment: AlignmentType.CENTER,}),
                    new Paragraph({
                        spacing: {
                            before: 100
                        }
                    }),
                    ...renderSection({ style: 'sectionTitle', text: "Education", heading: HeadingLevel.HEADING_1, thematicBreak: true, alignment: AlignmentType.CENTER}, resume.educations),
                    ...renderSection({ style: 'sectionTitle', text: "Work Experience", heading: HeadingLevel.HEADING_1, thematicBreak: true, alignment: AlignmentType.CENTER }, resume.experiences),
                    ...renderSection({ style: 'sectionTitle', text: "Personal Projects", heading: HeadingLevel.HEADING_1, thematicBreak: true, alignment: AlignmentType.CENTER }, resume.personalProjects),
                    ...renderSkills({ style: 'sectionTitle', text: "Skills", heading: HeadingLevel.HEADING_1, thematicBreak: true, alignment: AlignmentType.CENTER }),
                    ...renderReferences({ style: 'sectionTitle', text: "References", heading: HeadingLevel.HEADING_1, thematicBreak: true, alignment: AlignmentType.CENTER })
                ]
            }
        ]
    })
}