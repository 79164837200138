import { IParagraphOptions, ISpacingProperties, Paragraph, ParagraphChild, Tab, TabStopPosition, TabStopType, TextRun } from "docx";
import { MainSubsection, Reference, Skill, Social } from "../interfaces/resume.interface";
import { resume } from "../data/resume";

export const createMainSubSections = (subsections: MainSubsection[]): Paragraph[] => {
    return subsections.map((subsection: MainSubsection) => {
        const arr: Paragraph[] = [];
        arr.push(
            new Paragraph({
                tabStops: [
                    {
                        type: TabStopType.RIGHT,
                        position: TabStopPosition.MAX,
                    },
                ],
                children: [
                    new TextRun({ text: subsection.title, bold: true }),
                    new TextRun({ children: [new Tab(), `${("0" + ((subsection.startDate?.getMonth() || 0) + 1)).slice(-2) + "/" + subsection.startDate?.getFullYear()} - ${("0" + ((subsection.endDate?.getMonth() || 0) + 1)).slice(-2) + "/" + subsection.endDate?.getFullYear()}`], bold: true }),
                ],
            })
        );
        
        arr.push(new Paragraph({ children: [new TextRun({ text: subsection.subtitle, italics: true, })], }))

        const bulletPoints = subsection.description.split("\n\n");

        if(bulletPoints.length === 1 && bulletPoints[0].trim() === "") return arr;

        bulletPoints.forEach((bulletPoint: any) => {
            arr.push(new Paragraph({
                text: bulletPoint,
                bullet: {
                    level: 0,
                },
            }));
        });

        return arr;
    })
    .reduce((prev: any, curr: any) => prev.concat(curr), [])
}

export const renderHeader = (options: string | IParagraphOptions, options2: IParagraphOptions, innerSpacing2?: ISpacingProperties | undefined) => {
    const paragraphChildren: ParagraphChild[] = [
        new TextRun({
            text: `Mobile: ${resume.mobile} | LinkedIn: ${resume.linkedin} ${resume.socials.length > 0 ? '| ' + resume.socials.map((social: Social) => `${social.name}: ${social.link}`) : ''} | Email: ${resume.email}`,
            break: 1,                      
        })
    ]

    if(innerSpacing2) {
        paragraphChildren.push(
            new Paragraph({
                spacing: innerSpacing2
            })
        )
    }
    
    return [
        new Paragraph(options),
        new Paragraph({
            children: paragraphChildren,
            ...options2
        }),
    ]
}

export const renderSkills = (options: string | IParagraphOptions) => {
    if(resume.skills.length === 0) return [];

    return [
        new Paragraph(options),
        new Paragraph({
            children: [new TextRun(resume.skills.map((skill: Skill) => skill.name).join(", ") + ".")],
            spacing: {
                after: 100
            }
        }),

    ]
}

export const renderSection = (options: string | IParagraphOptions, subsections: MainSubsection[]) => {
    if(subsections.length === 0) return [];

    return [
        new Paragraph(options),
        ...createMainSubSections(subsections),
        new Paragraph({
            spacing: {
                after: 100
            }
        })
    ]
}

export const renderReferences = (options: string | IParagraphOptions) => {
    if(resume.references.length === 0) return [];

    return [                    
        new Paragraph(options),
        new Paragraph(resume.references.map((reference: Reference) => `${reference.fullname}, ${reference.role} (${reference.contacts}). `).join(""))
    ]
}