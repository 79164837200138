import { useState } from "react";
import './ResumeGenerator.scss';
import { NAVBAR_HEIGHT } from "../../ui/style.const";
import { StepType } from "../../types/stepType.enum";
import WorkExperience from "./steps/WorkExperience/WorkExperience";
import Education from "./steps/Education/Education";
import { Step } from "../../interfaces/step.interface";
import PersonalInformations from "./steps/PersonalInformations/PersonalInformations";
import Skills from "./steps/Skills/Skills";
import Contacts from "./steps/Contacts/Contacts";
import References from "./steps/References/References";
import ResumeGeneratorProgress from "./ResumeGeneratorProgress/ResumeGeneratorProgress";
import PersonalProjects from "./steps/PersonalProjects/PersonalProjects";
import Navbar from "../../components/Navbar/Navbar";

export default function ResumeGenerator() {

    const [currentStep, setCurrentStep] = useState<StepType>(StepType.PERSONAL_INFORMATIONS);

    const onSelectStep = (step: Step) => {
        setCurrentStep(step.step);
    }

    const onNavigate = (direction: -1 | 1) => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const steps = Object.values(StepType);
        const nextStep = steps[steps.indexOf(currentStep) + direction]
        setCurrentStep(nextStep);
    }

    return (
        <>
            <Navbar></Navbar>
            <div style={{ marginTop: '30px' }}>
                <div className="resume-generator">
                    <div className="progress-container">
                        <ResumeGeneratorProgress currentStep={currentStep} onSelectStep={(step) => onSelectStep(step)}></ResumeGeneratorProgress>
                    </div>
                    {
                        currentStep === StepType.PERSONAL_INFORMATIONS ? <PersonalInformations onNavigate={onNavigate}></PersonalInformations> :
                        currentStep === StepType.WORK_EXPERIENCE ? <WorkExperience onNavigate={onNavigate}></WorkExperience> :
                        currentStep === StepType.SKILLS ? <Skills onNavigate={onNavigate}></Skills> :
                        currentStep === StepType.CONTACTS ? <Contacts onNavigate={onNavigate}></Contacts> :
                        currentStep === StepType.PERSONAL_PROJECTS ? <PersonalProjects onNavigate={onNavigate}></PersonalProjects> :
                        currentStep === StepType.REFERENCES ? <References onNavigate={onNavigate}></References> :
                        <Education onNavigate={onNavigate}></Education>
                    }
                </div>
            </div>
        </>
    );
}