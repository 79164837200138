import FillOutIcon from "../Icons/FillOutIcon/FillOutIcon";
import ResumeIcon from "../Icons/ResumeIcon/ResumeIcon";
import ShareIcon from "../Icons/ShareIcon/ShareIcon";
import { IconType } from "../types/icon.type";

export const renderIcon = (iconType: IconType) => {
    switch(iconType){
        case 'fill-out' : return <FillOutIcon></FillOutIcon>;
        case 'resume-template' : return <ResumeIcon></ResumeIcon>;
        case 'share' : return <ShareIcon></ShareIcon>;
    }
}