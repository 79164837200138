import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Font from '../Font/Font';
import './Input.scss';
import { Validation } from '../../utilities/validate.utility';
import DatePicker from "react-datepicker";
import "./../datepicker.css";

interface InputProps {
    title?: string;
    placeholder?: string;
    value?: string;
    date?: Date | null;
    validators?: Validation.Validator[];
    type?: 'text' |  'number' | 'textarea' | 'date';
    hasMessage?: boolean;
    needToValidate?: boolean;
    onChangeValue?: (value: string) => void;
    onChangeDate?: (date: Date) => void;
    onPressEnter?: (value: string) => void;
}

const Input = ({
    title,
    placeholder,
    value,
    date,
    validators,
    type,
    hasMessage,
    onChangeValue,
    onChangeDate,
    onPressEnter,
    needToValidate=false
}: InputProps, outerRef: any) => {
    const innerRef = useRef<any>();
    useImperativeHandle(outerRef, () => innerRef.current!, []);
    const [validationResponse, setValidationResponse] = useState<Validation.ValidationResponse>();
    let isWaitingValidation = false;

    useEffect(() => {
        if(needToValidate) {
            validate();
        }
    })

    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const validate = async () => {
        if(isWaitingValidation || !validators) {
            return;
        }

        isWaitingValidation = true;

        sleep(150).then(() => {
            isWaitingValidation = false;
            setValidationResponse(Validation.validate(innerRef.current?.value, validators));
        })
    }

    const getClassName = (): string => {
        const classes = ['input-field'];

        if(validationResponse?.valid === false) classes.push('input-field-state--danger');

        if(type === 'textarea') classes.push('input-field--textarea');

        return classes.join(" ");
    }

    return (
        <div className={'input' + (type === 'textarea' ? ' input--textarea' : '')}>
            {
                title && (
                    <div className='input-title'>
                        <Font>{title}</Font>
                    </div>
                )
            }
            {
                type === 'textarea' ? (
                    <textarea 
                        ref={innerRef}
                        className={getClassName()}
                        placeholder={placeholder}
                        defaultValue={value}
                        onChange={(e) => {
                            onChangeValue?.(e.target.value);
                            validate();
                        }}
                    >
                    </textarea>
                ) :
                type === 'date' ? (
                    <DatePicker 
                        className={getClassName() + ' input-field-date'}
                        selected={date} 
                        onSelect={(date: Date) => {
                            onChangeDate?.(date);
                        }}
                        onChange={(date: Date) => {
                            onChangeDate?.(date);
                        }}
                    />
                ) : (
                    <input
                        type={type}
                        ref={innerRef}
                        className={getClassName()}
                        placeholder={placeholder}
                        defaultValue={value}
                        onChange={(e) => {
                            onChangeValue?.(e.target.value);
                            validate();
                        }}
                        onBlur={() => validate()}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter') {
                                onPressEnter?.(innerRef.current.value);
                            }
                        }}
                    >
                    </input>
                )
            }
            {
                (validators && hasMessage === undefined || hasMessage === true) && (
                    <div className='input-errors'>
                        {
                            validationResponse?.valid === false && <Font state='danger' weight='m' customSize='13px'>{validationResponse.errors[0].message}</Font>
                        }
                    </div>
                )
            }
        </div>
    );
}

export default forwardRef(Input);