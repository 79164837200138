import { Resume } from "../interfaces/resume.interface";
import { randomId } from "../utilities/randomId.utility";

export const resume: Resume = {
    name: "",
    lastname: "",
    mobile: "",
    state: "",
    city: "",
    linkedin: "",
    socials: [],
    email: "",
    skills: [],
    experiences: [],
    educations: [],
    personalProjects: [],
    references: [],
}

export const resume1: Resume = {
    name: "Marco",
    lastname: "Della Rosa",
    mobile: "+39 3896636322",
    state: "Italy",
    city: "Milan",
    linkedin: "https://www.linkedin.com/in/marcodellarosa/",
    socials: [
        {
            "name" : "GitHub",
            "link": "https://github.com/dellarosamarco",
            "id" : randomId()
        }
    ],
    email: "marcodellarosa90@gmail.com",
    skills: [
        {
            "name" : "Frontend Development",
            "id" : randomId()
        },
        {
            "name" : "Angular",
            "id" : randomId()
        },
        {
            "name" : "React",
            "id" : randomId()
        },
        {
            "name" : "Microfrontend",
            "id" : randomId()
        },
        {
            "name" : "Web Components",
            "id" : randomId()
        },
        {
            "name" : "Storybook",
            "id" : randomId()
        },
        {
            "name" : "Express",
            "id" : randomId()
        },
    ],
    experiences: [
        {
            title: "Company Name",
            subtitle: "Position",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
            startDate: new Date("06/21/2021"),
            endDate: new Date("05/12/2022"),
            hasEndDate: true,
            "id" : randomId()
        },
        {
            title: "Company Name",
            subtitle: "Position",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
            startDate: new Date("05/12/2022"),
            endDate: new Date("11/31/2022"),
            hasEndDate: true,
            "id" : randomId()
        },
        {
            title: "Company Name",
            subtitle: "Position",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
            startDate: new Date("12/01/2022"),
            endDate: new Date("09/01/2023"),
            hasEndDate: true,
            "id" : randomId()
        },
        {
            title: "Company name",
            subtitle: "Position",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
            startDate: new Date("09/01/2023"),
            endDate: new Date(),
            hasEndDate: false,
            "id" : randomId()
        }
    ],
    educations: [
        {
            title: "School name",
            subtitle: "Course name",
            startDate: new Date("06/21/2023"),
            endDate: new Date(),
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
            hasEndDate: false,
            "id" : randomId()
        },
        {
            title: "School name",
            subtitle: "Course name",
            startDate: new Date("06/21/2023"),
            endDate: new Date(),
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
            hasEndDate: false,
            "id" : randomId()
        },
    ],
    personalProjects: [
        {
            title: "Project name",
            subtitle: "https://www.projectlink.com",
            startDate: new Date("06/21/2023"),
            endDate: new Date(),
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took",
            hasEndDate: false,
            "id" : randomId()
        },
        {
            title: "Project name",
            subtitle: "https://www.projectlink.com",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
            startDate: new Date("06/21/2023"),
            endDate: new Date(),
            hasEndDate: false,
            "id" : randomId()
        }
    ],
    references: [],
}