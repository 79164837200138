import { useState } from "react";
import { resume } from "../../../../data/resume";
import Button from "../../../../ui/Button/Button";
import Font from "../../../../ui/Font/Font";
import Input from "../../../../ui/Input/Input";
import { Validation } from "../../../../utilities/validate.utility";
import { useNavigate } from "react-router-dom";

interface PersonalInformationsProps {
    onNavigate: (direction: -1 | 1) => void
}

interface FormValidators {
    nameValidators: Validation.Validator[];
    lastnameValidators: Validation.Validator[];
}

export default function PersonalInformations({
    onNavigate
}: PersonalInformationsProps) {
    const navigate = useNavigate();

    const [needToValidate, setNeedToValidate] = useState<boolean>(false);

    const formValidators: FormValidators = {
        nameValidators: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: "Name is not valid"
            }
        ],
        lastnameValidators: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: "Lastname is not valid"
            }
        ]
    }

    const validateForm = () => {
        if(!needToValidate) {
            setNeedToValidate(true)
        }

        if(
            Validation.validate(resume.name, formValidators.nameValidators).valid &&
            Validation.validate(resume.lastname, formValidators.lastnameValidators).valid
        ){
            onNavigate(1);
        }
    }

    return (
        <div className="resume-generator__section">
            <h1>Personal Informations</h1>

            <div className="resume-generator__section-form">
                <div className="resume-generator__section-form__line">
                    <Input 
                        value={resume.name} 
                        onChangeValue={(value: string) => resume.name = value} 
                        placeholder="Enter your name" 
                        title="Name"
                        validators={formValidators.nameValidators}
                        needToValidate={needToValidate}
                    />
                    <Input 
                        value={resume.lastname} 
                        onChangeValue={(value: string) => resume.lastname = value} 
                        placeholder="Enter your lastname" 
                        title="Lastname"
                        validators={formValidators.lastnameValidators}
                        needToValidate={needToValidate}
                    />
                    <Input 
                        value={resume.state} 
                        onChangeValue={(value: string) => resume.state = value} 
                        placeholder="Enter your state" 
                        title="State"
                        validators={[]}
                    />
                    <Input 
                        value={resume.city} 
                        onChangeValue={(value: string) => resume.city = value} 
                        placeholder="Enter your city" 
                        title="City"
                        validators={[]}
                    />
                </div>
            </div>

            <div className="resume-generator__section-navigation">
                <Button text="Back" skin="dark" onClick={() => navigate('/')}></Button>
                <Button 
                    text="Next" 
                    skin="action" 
                    onClick={() => {
                        validateForm();
                    }}
                ></Button>
            </div>
        </div>
    )
}