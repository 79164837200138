import { useState } from "react";
import { resume } from "../../../../data/resume";
import { Social } from "../../../../interfaces/resume.interface";
import Button from "../../../../ui/Button/Button";
import Font from "../../../../ui/Font/Font";
import Input from "../../../../ui/Input/Input";
import { Validation } from "../../../../utilities/validate.utility";
import './Contacts.scss';
import { randomId } from "../../../../utilities/randomId.utility";

interface ContactsProps {
    onNavigate: (direction: -1 | 1) => void;
}

interface FormValidators {
    phoneNumberValidators: Validation.Validator[];
    emailValidators: Validation.Validator[];
    socialNameValidators: Validation.Validator[];
    socialLinkValidators: Validation.Validator[];
}

export default function Contacts({
    onNavigate
}: ContactsProps){
    const [refresh, forceRefresh] = useState(true);

    const [needToValidate, setNeedToValidate] = useState<boolean>(false);

    const formValidators: FormValidators = {
        phoneNumberValidators: [
            {
                validatorType: Validation.ValidationType.PHONE_NUMBER,
                message: "Phone number is not valid"
            }
        ],
        emailValidators: [
            {
                validatorType: Validation.ValidationType.EMAIL,
                message: "Email is not valid"
            }
        ], 
        socialNameValidators: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: ""
            }
        ],
        socialLinkValidators: [
            {
                validatorType: Validation.ValidationType.REQUIRED,
                message: ""
            }
        ]
    }

    const validateForm = () => {
        if(!needToValidate) {
            setNeedToValidate(true)
        }

        let socialsValid = true;

        for(let n=0;n<resume.socials.length;n++) {
            if(
                !Validation.validate(resume.socials[n].name, formValidators.socialNameValidators).valid ||
                !Validation.validate(resume.socials[n].link, formValidators.socialLinkValidators).valid
            ) {
                socialsValid = false;
                break;
            }
        }

        if(
            Validation.validate(resume.mobile, formValidators.phoneNumberValidators).valid &&
            Validation.validate(resume.email, formValidators.emailValidators).valid &&
            socialsValid
        ){
            onNavigate(1);
        }
    }

    const onAddContact = () => {
        resume.socials.push({
            name: '',
            link:'',
            id: randomId()
        });

        forceRefresh(!refresh);
    }

    const onDeleteContact = (id: string) => {
        resume.socials = resume.socials.filter((social: Social) => social.id !== id);

        forceRefresh(!refresh);
    }

    return (
        <div className="resume-generator__section">
            <h1>Contacts</h1>

            <div className="resume-generator__section-form">
                <div className="resume-generator__section-form__line">
                    <Input 
                        value={resume.mobile} 
                        onChangeValue={(value: string) => resume.mobile = value} 
                        placeholder="Enter your phone number" 
                        title="Phone number"
                        validators={formValidators.phoneNumberValidators}
                        needToValidate={needToValidate}
                    />
                    <Input 
                        value={resume.email} 
                        onChangeValue={(value: string) => resume.email = value} 
                        placeholder="Enter your email" 
                        title="Email"
                        validators={formValidators.emailValidators}
                        needToValidate={needToValidate}
                    />
                    <Input 
                        value={resume.linkedin} 
                        onChangeValue={(value: string) => resume.mobile = value} 
                        placeholder="Enter your LinkedIn profile" 
                        title="LinkedIn"
                        validators={[]}
                    />
                </div>
                <Button 
                    text="Add new contact" 
                    skin="action" 
                    style={{ height: '36px', minWidth: '5rem' }}
                    onClick={onAddContact}
                ></Button>
                {
                    resume.socials.length > 0 && <div className="contacts-container">
                        {
                            resume.socials.map(
                                (social: Social) => <div className="resume-generator__section-form__line" style={{alignItems:'end'}}>
                                    <Input
                                        key={randomId()}
                                        value={social.name} 
                                        onChangeValue={(value: string) => social.name = value} 
                                        placeholder="ex. Linkedin" 
                                        title="Name"
                                        validators={formValidators.socialNameValidators}
                                        needToValidate={needToValidate}
                                        hasMessage={false}
                                    />
                                    <Input
                                        key={randomId()}
                                        value={social.link} 
                                        onChangeValue={(value: string) => social.link = value} 
                                        placeholder="ex. www.linkedin.com/profile/" 
                                        title="Link"
                                        validators={formValidators.socialLinkValidators}
                                        needToValidate={needToValidate}
                                        hasMessage={false}
                                    />
                                    <Button 
                                        key={randomId()}
                                        text="Delete" 
                                        skin="danger" 
                                        style={{ height: '36px', minWidth: '5rem' }}
                                        onClick={() => onDeleteContact(social.id)}
                                    ></Button>
                                </div>
                            )
                        }
                    </div>
                }
            </div>

            <div className="resume-generator__section-navigation">
                <Button text="Back" skin="dark" onClick={() => onNavigate(-1)}></Button>
                <Button text="Next" skin="action" onClick={() => validateForm()}></Button>
            </div>
        </div>
    );
}