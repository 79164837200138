import AchievementsIcon from "../Icons/AtsSystemPointsIcons/AchievementsIcon/AcheivementsIcon";
import ExtensionIcon from "../Icons/AtsSystemPointsIcons/ExtensionIcon/ExtensionIcon";
import HeaderIcon from "../Icons/AtsSystemPointsIcons/HeaderIcon/HeaderIcon";
import KeywordIcon from "../Icons/AtsSystemPointsIcons/KeywordIcon/KeywordIcon";
import SimpleFormatIcon from "../Icons/AtsSystemPointsIcons/SimpleFormatIcon/SimpleFormatIcon";
import SkillsIcon from "../Icons/AtsSystemPointsIcons/SkillsIcon/SkillsIcon";
import SpellingIcon from "../Icons/AtsSystemPointsIcons/SpellingIcon/SpellingIcon";
import TextIcon from "../Icons/AtsSystemPointsIcons/TextIcon/TextIcon";
import { UnusualCharactersIcon } from "../Icons/AtsSystemPointsIcons/UnusualCharactersIcon/UnusualCharactersIcon";
import { AtsSystemPoint } from "../interfaces/atsSystemPoint.interface";

export const atsSystemPoints: AtsSystemPoint[] = [
    {
        "title": "Use a Simple and Clean Format",
        "description": "Use a straightforward layout with standard fonts, avoiding complex designs and images for ATS clarity.",
        "icon": <SimpleFormatIcon></SimpleFormatIcon>
    },
    {
        "title": "Choose an ATS-Compatible File Format",
        "description": "Save your resume as a .docx or .pdf to ensure compatibility with most ATS systems.",
        "icon": <ExtensionIcon></ExtensionIcon>
    },
    {
        "title": "Incorporate Relevant Keywords",
        "description": "Include job-specific keywords and industry terms to align with the job posting.",
        "icon": <KeywordIcon></KeywordIcon>
    },
    {
        "title": "Prioritize Text over Graphics",
        "description": "Focus on text over images or charts for better ATS parsing.",
        "icon": <TextIcon></TextIcon>
    },
    {
        "title": "Include a Skills Section",
        "description": "List key skills, matching terms in the job description, in a dedicated section.",
        "icon": <SkillsIcon></SkillsIcon>
    },
    {
        "title": "Optimize Job Titles and Headings",
        "description": "Use standard job titles and clear headings like 'Work Experience' and 'Education'.",
        "icon": <HeaderIcon></HeaderIcon>
    },
    {
        "title": "Quantify Achievements and Responsibilities",
        "description": "Detail achievements with metrics and numbers to demonstrate impact.",
        "icon": <AchievementsIcon></AchievementsIcon>
    },
    {
        "title": "Avoid Unusual Fonts and Characters",
        "description": "Use common fonts like Arial, avoiding special characters or symbols.",
        "icon": <UnusualCharactersIcon></UnusualCharactersIcon>
    },
    {
        "title": "Check Spelling and Grammar",
        "description": "Proofread for typos and grammatical errors to maintain professionalism.",
        "icon": <SpellingIcon></SpellingIcon>
    }
]