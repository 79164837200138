import { useState } from 'react'
import LeftArrowIcon from '../../Icons/LeftArrowIcon/LeftArrowIcon'
import RightArrowIcon from '../../Icons/RightArrowIcon/RightArrowIcon'
import './Slick.scss';
import { resumeTemplates } from '../../data/resumeTemplates';
import { ResumeTemplate } from '../../interfaces/resumeTemplate.interface';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';

const slickItemWidth = window.innerWidth < 1400 ? 275 : 300;
const slickItemMargin = 5;
const totalItems = window.innerWidth < 768 ? 1 : window.innerWidth < 992 ? 2 : window.innerWidth < 1400 ? 3 : 4;

export default function Slick(){

    const navigate = useNavigate();

    const [currentIndex, setCurrentIndex] = useState(0);

    const [slickPosition, setSlickPosition] = useState(0);

    const onMove = (direction: 1 | -1) => {
        setSlickPosition(slickPosition + ((slickItemWidth + (slickItemMargin * 2)) * direction));
        setCurrentIndex(currentIndex-direction)
    }

    return (
        <div className='slick-wrapper'>
            {
                (currentIndex !== 0) ? <div className='slick-arrow' onClick={() => onMove(1)}>
                    <LeftArrowIcon></LeftArrowIcon>
                </div> : <div className='slick-empty-arrow'></div>
            }
            <div className='slick'>
                <div 
                    className="slick-container"
                    style={{
                        left: slickPosition + 'px'
                    }}
                >
                    {
                        resumeTemplates.map((item: ResumeTemplate) => {
                            return (
                                <div className="slick-item">
                                    <img className="slick-item--image" src={item.preview}></img>

                                    <div className='slick-item-selector'>
                                        <Button text='USE TEMPLATE' skin='action' onClick={() => navigate('/resume-generator')}></Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                (currentIndex !== resumeTemplates.length - 1 - (totalItems - 1)) ? <div className='slick-arrow' onClick={() => onMove(-1)}>
                    <RightArrowIcon></RightArrowIcon>
                </div> : <div className='slick-empty-arrow'></div>
            }
        </div>
    )
}