const KeywordIcon = (props: any) => (
  <svg
    fill="#b4b6de"
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M28.2,23.9l-5.2-5.4c0.5-1.1,0.9-2.3,1-3.5H26c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2.1c-0.5-4.7-4.2-8.5-8.9-8.9V2 c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.1C8.3,4.5,4.5,8.3,4.1,13H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2.1c0.5,4.7,4.2,8.5,8.9,8.9V26 c0,0.6,0.4,1,1,1s1-0.4,1-1v-2.1c1.3-0.1,2.5-0.5,3.6-1.1l5.3,5.3c0.6,0.6,1.4,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9 C29.3,27,29.3,25.1,28.2,23.9z M14,19c-0.6,0-1,0.4-1,1v1.9c-3.6-0.5-6.5-3.3-6.9-6.9H8c0.6,0,1-0.4,1-1s-0.4-1-1-1H6.1 C6.5,9.4,9.4,6.5,13,6.1V8c0,0.6,0.4,1,1,1s1-0.4,1-1V6.1c3.6,0.5,6.5,3.3,6.9,6.9H20c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.9 c-0.5,3.6-3.3,6.5-6.9,6.9V20C15,19.4,14.6,19,14,19z" />
      <path d="M17,14c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3S17,15.7,17,14z" />
    </g>
  </svg>
);
export default KeywordIcon;