import { useEffect, useState } from "react";
import { atsSystemPoints } from "../../../data/atsSystemPoints";
import { AtsSystemPoint } from "../../../interfaces/atsSystemPoint.interface";
import Font from "../../../ui/Font/Font";
import './AtsSystem.scss';

export default function AtsSystem() {

    const [filteredAtsSystemPoints, setFilteredAtsSystemPoints] = useState(atsSystemPoints);

    const checkScreenSize = () => {
        if(window.innerWidth >= 1201) {
            setFilteredAtsSystemPoints(atsSystemPoints);
            return;
        }

        if(window.innerWidth < 787) {
            setFilteredAtsSystemPoints(atsSystemPoints.slice(0, atsSystemPoints.length-5));
            return;
        }

        if(window.innerWidth < 1201) {
            setFilteredAtsSystemPoints(atsSystemPoints.slice(0, atsSystemPoints.length-1));
            return;
        }
    }

    useEffect(() => {
        checkScreenSize();
    }, [])

    return (
        <div className="ats-system" id="ats-system-section">
            <h1 className="section-title">ATS system</h1>

            <div className="ats-system-content">
                {
                    filteredAtsSystemPoints.map((atsSystemPoint: AtsSystemPoint) => {
                        return (
                            <div className="ats-system-content--card">
                                <div className="ats-system-content--card-icon">
                                    { atsSystemPoint.icon }
                                </div>
                                <div className="ats-system-content--card-content">
                                    <div className="ats-system-content--card-content__title">
                                        <Font weight="lg" customSize="17.5px" state="action">{ atsSystemPoint.title }</Font>
                                    </div>
                                    <div className="ats-system-content--card-content__description">
                                        <Font>{ atsSystemPoint.description }</Font>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}