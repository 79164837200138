import { AlignmentType, Document, HeadingLevel, Paragraph, TextRun } from "docx";
import { renderHeader, renderReferences, renderSection } from "./resume.helper";
import { resume } from "../data/resume";
import { Skill } from "../interfaces/resume.interface";

export const getTemplate4 = () => {
    return new Document({
        sections : [
            {
                children : [
                    ...renderHeader({ text: resume.name + " " + resume.lastname, heading: HeadingLevel.TITLE, alignment: AlignmentType.LEFT }, { alignment: AlignmentType.LEFT,}),
                    new Paragraph({
                        spacing: {
                            before: 20
                        }
                    }),
                    new Paragraph({
                        children : [
                            new TextRun({ text: "Skills : ", size: 20, bold: true }),
                            new TextRun(resume.skills.map((skill: Skill) => skill.name).join(", ") + "."),
                        ]
                    }),
                    new Paragraph({
                        spacing: {
                            after: 20
                        }
                    }),
                    ...renderSection({ text: "Education", heading: HeadingLevel.HEADING_1, thematicBreak: true, }, resume.educations),
                    ...renderSection({ text: "Work Experience", heading: HeadingLevel.HEADING_1, thematicBreak: true, }, resume.experiences),
                    ...renderSection({ text: "Personal Projects", heading: HeadingLevel.HEADING_1, thematicBreak: true, }, resume.personalProjects),
                    ...renderReferences({ text: "References", heading: HeadingLevel.HEADING_1, thematicBreak: true, })
                ]
            }
        ]
    })
}