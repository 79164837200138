import Card from "../../../ui/Card/Card";
import Font from "../../../ui/Font/Font";
import './About.scss';

export default function About(){
    return (
        <div className="about" id="about-section">
            <h1 className="section-title">Create your own ATS friendly Resume in few minutes</h1>

            <div className="about-content">
                <Card 
                    icon="resume-template"
                    title="Select the template" 
                    description="Every model is created to be ATS-friendly, ensuring optimal performance in applicant tracking systems."
                ></Card>
                <Card 
                    icon="fill-out"
                    title="Fill it out in 15 minutes" 
                    description="Fill out your resume in a few minutes by using our easy-to-use editor."
                ></Card>
                <Card 
                    icon="share"
                    title="Download and share it" 
                    description="Download your resume and share it with the recruiters to enhance your job-seeking journey."
                ></Card>
            </div>
        </div>
    );
}