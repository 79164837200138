import { useNavigate } from "react-router-dom";
import Button from "../../../ui/Button/Button";
import './GetStarted.scss';

export default function GetStarted() {
    const navigate = useNavigate();
    
    return (
        <div className="getstarted" id="home-section" style={{ backgroundImage: "url('get-started-background-mod.jpg')"}}>
            <div className="getstarted_left-side">
                <div className="getstarted_left-side_title">
                    <h1 className="home-title">Generate your ATS friendly resume for Free</h1>
                </div>
                <div className="getstarted_left-side_subtitle">
                    <p className="home-subtitle">ats.friendly is a user-friendly platform that lets you create your ATS-friendly resume, a valuable asset you can confidently use for job applications, and the best part is, it's 100% free.</p>
                </div>
                <div className="getstarted_left-side_actions">
                    <Button skin="action" style={{ color: 'var(--secondary-text-color)' }} text="Create your resume" onClick={() => navigate('/resume-generator')}></Button>
                </div>
            </div>
            <div className="getstarted_right-side">
                <img className="getstarted_right-side_image" src='get-started-image.png' alt=''></img>
            </div>
        </div>
    );
}