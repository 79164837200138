const HeaderIcon = (props: any) => (
  <svg
    fill="#b4b6de"
    viewBox="0 0 24 24"
    id="heading"
    data-name="Line Color"
    xmlns="http://www.w3.org/2000/svg"
    className="icon line-color"
    {...props}
  >
    <path
      id="primary"
      d="M6,3V21m12,0V3M8,3H4M16,3h4M8,21H4m12,0h4M6,12H18"
      style={{
        fill: "#b4b6de",
        stroke: "#b4b6de",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
  </svg>
);
export default HeaderIcon;