import './Home.scss';
import GetStarted from "./GetStarted/GetStarted";
import About from "./About/About";
import Navbar from '../../components/Navbar/Navbar';
import Templates from './Templates/Templates';
import AtsSystem from './AtsSystem/AtsSystem';
import Faq from './Faq/Faq';

export default function Home() {
    return (
        <>
            <Navbar></Navbar>
            <GetStarted></GetStarted>
            <About></About>
            <Templates></Templates>
            <AtsSystem></AtsSystem>
            <Faq></Faq>
        </>
    );
}