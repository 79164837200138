const RemoveIcon = (props: any) => (
    <svg
        viewBox="0 0 18 18"
        {...props}
    >
        <path
            d="M14.25 4.808 13.193 3.75 9 7.942 4.808 3.75 3.75 4.808 7.942 9 3.75 13.193l1.058 1.057L9 10.057l4.193 4.193 1.057-1.057L10.057 9l4.193-4.192Z"
            fillRule="evenodd"
            fill="white"
            opacity={0.4}
        />
    </svg>
);
export default RemoveIcon;