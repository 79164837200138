import { useState } from "react";
import Font from "../Font/Font";
import './Accordion.scss';

interface AccordionProps {
    title: string;
    content: string;
}

export default function Accordion({
    title,
    content
}: AccordionProps){

    const [isOpened, setIsOpened] = useState(false);

    return (
        <div className="accordion">
            <div className="accordion-header" onClick={() => setIsOpened(!isOpened)}>
                <Font size="m" weight="m">{title}</Font>
            </div>
            <div className={'accordion-content ' + (isOpened ? 'accordion-opened' : 'accordion-closed')}>
                <Font weight="m">{content}</Font>
            </div>
        </div>
    )
}