import './Font.scss';

export interface FontProps {
    state?: 'default' | 'danger' | 'action';
    children?: string;
    element?: 'p' | 'h1';
    size?: 'sm' | 'm' | 'lg';
    customSize?: string;
    weight?: 'sm' | 'm' | 'lg';
    align?: 'left' | 'center' | 'right';
    isTransparent?: boolean;
}

export default function Font({
    state,
    element='p',
    size='sm',
    weight='sm',
    isTransparent=false,
    children,
    customSize,
    align
}: FontProps){

    const getClassName = (): string => {
        const classes = ["font"];

        classes.push(`font-state--${state}`)
        classes.push(`font-size--${size}`)
        classes.push(`font-weight--${weight}`)
        classes.push(`font-align--${align}`)

        return classes.join(" ");
    }

    const getStyle = () => {
        const style: React.CSSProperties = {};

        if(customSize) {
            style.fontSize = customSize;
        }

        if(isTransparent) {
            style.opacity = 0.6;
        }

        return style;
    }

    if(element === 'p') {
        return (
            <p className={getClassName()} style={getStyle()}>{children}</p>
        );
    }
    else{
        return (
            <h1 className={getClassName()} style={getStyle()}>{children}</h1>
        );
    }
}